import React from 'react';
import styled from 'styled-components';

const StyledGroup = styled.g`
    transform-origin: 50% 50%;
    transform: rotate(0deg) scale(0.8, 0.8);
    animation-play-state: paused;
`;
const StyledPath = styled.path`
    fill: rgb(25, 152, 213);
    animation-play-state: paused;
`;

type Props = {
}

function DeleteIcon(props: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" y="0" x="0" width="25px" height="25px">
            <StyledGroup>
                <StyledPath
                    fill="#e15b64" d="M69.863 19.947L50 39.81 30.137 19.946c-2.809-2.809-7.379-2.809-10.189 0s-2.809 7.38 0 10.189l19.863 19.863-19.863 19.864c-2.809 2.809-2.809 7.38 0 10.189s7.379 2.809 10.189 0L50 60.188l19.863 19.863c2.809 2.809 7.379 2.809 10.189 0s2.809-7.38 0-10.189L60.189 49.998l19.863-19.863c2.809-2.809 2.809-7.38 0-10.189s-7.379-2.808-10.189.001z" />
            </StyledGroup>
        </svg>
    );
}

export default DeleteIcon;