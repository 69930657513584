import React from 'react';
import styled from 'styled-components';

const StyledGroup = styled.g`
`;
const StyledPath = styled.path`
    fill: #178019;
`;

type Props = {
}

function CompleteIcon(props: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40px" height="40px">
            <StyledGroup>
                <StyledPath d="M18.3,6.3L9.1,16.4l-2.3-3c-0.3-0.4-1-0.5-1.4-0.2c-0.4,0.3-0.5,1-0.2,1.4l3,4C8.4,18.8,8.7,19,9,19c0,0,0,0,0,0   c0.3,0,0.5-0.1,0.7-0.3l10-11c0.4-0.4,0.3-1-0.1-1.4C19.3,5.9,18.6,5.9,18.3,6.3z" />
            </StyledGroup>
        </svg>
    );
}

export default CompleteIcon;