{
	"This link has expired": "Este enlace ha caducado.",
	"Secure Connection Established": "Se ha establecido una conexión segura.",
	"Select a File to begin (5 files max)": "Selecciona un archivo para comenzar (máx. 5 archivos).",
	"Maximum File Size: 20 MB": "Tamaño máximo del archivo: 20 MB",
	"Drag and Drop file or Browse": "Arrastra y suelta el archivo o búscalo en la carpeta de archivos.",
	"Upload": "Cargar",
	"Neighbor Tools": "Herramientas de usuario",
	"File Upload": "Carga del archivo",
	"file(s) selected for upload": "archivo(s) seleccionado(s) para cargar",
	"Maximum of 5 files": "Máximo 5 archivos",
	"Upload in progress": "Carga en curso",
	"Upload Successful": "Carga completada",
	"File is larger than 20 MB": "El archivo tiene más de 20 MB.",
	"File name has multiple file extensions": "El nombre del archivo tiene varias extensiones.",
	"File name cannot start with . or ..": "El nombre del archivo no puede empezar por “.” ni por “..”.",
	"Maximum of 5 files": "Máximo 5 archivos",
	"This link is expiring soon:": "Este enlace caduca pronto:",
	"minutes": "minutos",
	"less than a minute": "menos de un minuto",
	"1 minute": "1 minuto",
	"Secure Connection Established": "Se ha establecido una conexión segura.",
	"Drop the file here": "Suelta el archivo aquí.",
	"An error occurred while uploading": "Se ha producido un error al cargar.",
	"File Type is not Supported": "El tipo de archivo no es compatible.",
	"BadRequestError: File name contains invalid characters": "BadRequestError: El nombre del archivo contiene caracteres no válidos.",
	"An error has occurred": "Se ha producido un error",
	"Upload request has already been used": "Ya se ha utilizado la solicitud de carga"
}