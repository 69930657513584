import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        detection: {
            order: ['navigator', 'htmlTag'],
        },
        ns: ['common'], // Namespace for translation keys
        interpolation: {
            escapeValue: false, // React already safes from XSS
        },
        resources: {
            'en-US': {
                common: require('./locales/en-US/common.json')
            },
            'en-GB': {
                common: require('./locales/en-GB/common.json')
            },
            ar: {
                common: require('./locales/ar/common.json')
            },
            de: {
                common: require('./locales/de/common.json')
            },
            es: {
                common: require('./locales/es/common.json')
            },
            fr: {
                common: require('./locales/fr/common.json')
            },
            it: {
                common: require('./locales/it/common.json')
            },
            ja: {
                common: require('./locales/ja/common.json')
            },
            nl: {
                common: require('./locales/nl/common.json')
            }
        }
    });

export default i18n;