{
	"This link has expired": "This link has expired",
	"Secure Connection Established": "Secure Connection Established",
	"Select a File to begin (5 files max)": "Select a File to begin (5 files max)",
	"Maximum File Size: 20 MB": "Maximum File Size: 20 MB",
	"Drag and Drop file or Browse": "Drag and Drop file or Browse",
	"Upload": "Upload",
	"Neighbor Tools": "Neighbor Tools",
	"File Upload": "File Upload",
	"file(s) selected for upload": "file(s) selected for upload",
	"Maximum of 5 files": "Maximum of 5 files",
	"Upload in progress": "Upload in progress",
	"Upload Successful": "Upload Successful",
	"File is larger than 20 MB": "File is larger than 20 MB",
	"File name has multiple file extensions": "File name has multiple file extensions",
	"File name cannot start with . or ..": "File name cannot start with . or ..",
	"Maximum of 5 files": "Maximum of 5 files",
	"This link is expiring soon:": "This link is expiring soon:",
	"minutes": "minutes",
	"less than a minute": "less than a minute",
	"1 minute": "1 minute",
	"Secure Connection Established": "Secure Connection Established",
	"Drop the file here": "Drop the file here",
	"An error occurred while uploading": "An error occurred while uploading",
	"File Type is not Supported": "File Type is not Supported",
	"BadRequestError: File name contains invalid characters": "BadRequestError: File name contains invalid characters",
	"An error has occurred": "An error has occurred",
	"Upload request has already been used": "Upload request has already been used"
}