import axios from 'axios';
import 'url-polyfill';
import { Buffer } from 'buffer';
import crypto from 'crypto';

export const postToSigned = async (presignedUrl: string) => {
    const urlObj = new URL(presignedUrl);
    const body = JSON.parse(urlObj.searchParams.get('body') || '');
    const headers = JSON.parse(urlObj.searchParams.get('headers') || '');
    urlObj.searchParams.delete('body');
    urlObj.searchParams.delete('headers');
    const result = await axios.post(urlObj.toString(), body, { headers });
    return result.data;
};

export const encryptS3Data = async (presignedEncryptUrl: string, data: Buffer) => {
    const encryptionContext = {
        'aws:x-amz-cek-alg': 'AES/GCM/NoPadding',
    };

    let key: Buffer;
    let encryptedKey: Buffer;
    try {
        const response: any = await postToSigned(presignedEncryptUrl);
        key = Buffer.from(response.Plaintext, 'base64');
        encryptedKey = Buffer.from(response.CiphertextBlob, 'base64');
    } catch (err: any) {
        console.error(err.message);
        console.error(err.response?.data);
        throw new Error(err.message);
    }

    const ivLength = 12;
    const envelope_iv = crypto.randomBytes(ivLength);
    const cipher = crypto.createCipheriv('aes-256-gcm', key, envelope_iv);

    const encrypted = cipher.update(data);
    cipher.final();
    const authTag = cipher.getAuthTag();

    const authTagBits = authTag.byteLength * 8;

    const metadata: Record<string, string> = {
        'x-amz-matdesc': JSON.stringify(encryptionContext),
        'x-amz-tag-len': authTagBits.toString(),
        'x-amz-unencrypted-content-length': data.byteLength.toString(),
        'x-amz-wrap-alg': 'kms+context',
        'x-amz-key-v2': encryptedKey.toString('base64'),
        'x-amz-cek-alg': 'AES/GCM/NoPadding',
        'x-amz-iv': envelope_iv.toString('base64'),
    };

    const encryptedData = Buffer.concat([encrypted, authTag]);

    return { encryptedData, metadata };
};
