import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { useSearchParams } from 'react-router-dom';
import { catchError, from, lastValueFrom, map, mergeMap, of, tap, toArray } from 'rxjs';
import styled from 'styled-components';
import { Branding } from './Branding';
import CompleteIcon from '../images/CompleteIcon';
import DeleteIcon from '../images/DeleteIcon';
import DownloadIcon from '../images/DownloadIcon';
import settings from "../settings.json";
import './font-face.css';
import { useTranslation, getI18n } from 'react-i18next';
import { encryptS3Data } from '../utils/KmsUtil';

//API Prefix & OktaAuth Defined
const {
    api_prefix: apiPrefix,
} = (settings as any)[window.location.host] || settings.default;

const ALLOWED_FILE_TYPES = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
    'image/png': ['.png'],
    'text/plain': ['.txt'],
};

const KB = 1024;
const MB = KB * 1024;
const GB = MB * 1024;

const MAX_FILE_SIZE = 20 * MB;

const beautifyBytes = (bytes: number) => {
    if (bytes >= GB) {
        return `${Math.floor(bytes / GB)} GB`;
    } else if (bytes >= MB) {
        return `${Math.floor(bytes / MB)} MB`;
    } else if (bytes >= KB) {
        return `${Math.floor(bytes / KB)} KB`;
    } else {
        return `${Math.floor(bytes)} bytes`;
    }
};

const Page = styled.main<{
    vh: number;
}>`
    width: 100vw;
    height: ${(props) => `${props.vh}px`};
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    font: 16px equiplight;
    width: 100%;
    position: relative;
    max-width: 1400px;
    align-self: center;
`;

// Styled Components
const FileUploadDiv = styled.div`
    height: 100%;
    width: 100%;
    width: -webkit-fill-available;
    max-width: 1150px;
    margin: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;
const FileUploadFooter = styled.div`
    width: 100%;
    margin: auto 25px 10px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 380px) {
        flex-direction: column-reverse;
    }

`;
const FileUploadRow = styled.div`
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

// Header Components
const FileUploadHeader = styled.div`
    font-size: 15px;
    margin-top: 10px;
    width: 100%;
`;
// header Status indicators
const FileUploadStatus = styled.p`
    background-color: #AFDBF1;
    margin: 5px auto;
    padding: 8px 40px;

    color: #fff;
    font-family: 'equiplight';
    text-align: center;
`;
const FileUploadSuccess = styled.p`
    background-color: #83C284;
    margin: 5px auto;
    padding: 8px 40px;

    color: #fff;
    font-family: 'equiplight';
    text-align: center;
`;
const FileUploadError = styled.p`
    background-color: #EF7D76;
    margin: 5px auto;
    padding: 8px 40px;

    color: #fff;
    font-family: 'equiplight';
    text-align: center;
`;
const FileUploadProgress = styled.p`
    background-color: #FACAAB;
    margin: 5px auto;
    padding: 8px 40px;

    color: #fff;
    font-family: 'equiplight';
    text-align: center;
`;

// Components within Dropzone Column
const FileUploadDropzoneColumn = styled.div`
    height: 70vh;
    width: 550px;
    margin: 10px;

    @media (max-width: 1150px) {
        width: 95%;
        height: 50%;
    }
`;
const FileDropzone = styled.div<{
    isDragActive: boolean;
}>`
    min-height: 250px;
    height: -webkit-fill-available;
    height: 100%;
    margin: auto;
    padding: 25px;
    font-family: 'equiplight';

    box-sizing: border-box;
    border: 5px dashed ${props => props.isDragActive ? 'black' : 'gray'};
    border-radius: 25px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;

    & > p {
        text-align: center;
        transition: font-size 150ms;
    }

    & > p.sm {
        font-size: 14px;
        margin: 5px 0px;
    }
    & > p.lg {
        font-size: 20px;
        margin: 10px 0px;
    }

    transition: border 150ms;

    &:hover {
        border: 5px dashed #10628A;

        & > p.sm {
            font-size: 16px;
        }
        & > p.lg {
            font-size: 22px;
        }
    }
`;
const IconWrapper = styled.div`
    width: 50px;
    margin: 0px auto;
`;
const BrowseIndicator = styled.span`
    color: #1998d5;
    font-weight: 500;
`;
const DisabledText = styled.p`
    width: fit-content;
    color: red;
    font-size: 12px;
    font-family: 'equiplight';
    margin: 5px auto;
`;

// Components within List Colunn
const FileUploadListColumn = styled.div`
    height: 70vh;
    width: 550px;
    margin: 10px;

    @media (max-width: 1150px) {
        width: 95%;
        height: fit-content;
        min-height: 50%;
    }
`;
const FileUploadList = styled.div`
    background-color: rgba(66, 66, 66, 0.1);
    margin: auto;
    padding: 10px;

    @media (min-width: 1150px) {
        height: -webkit-fill-available;
        overflow: scroll;
    }
`;

const FileProgressBar = styled.span<{
    progress: number;
}>`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${props => Math.round(props.progress * 100)}%;
    background: #AFDBF1;
    transition: all 1s;
    z-index: 3;
`;

const FileUploadItem = styled.div`
    padding: 10px;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.65);
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    z-index: 2;
`;

const FileUploadDetailsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center; 
    z-index: 4;
    
    @media (max-width: 350px) {
        flex-wrap: wrap;
    }

`;
const FileUploadIcon = styled.div<{
    status: string;
}>`
    background-color: ${props => ({
        error: '#F7B9B6',
        complete: '#BCDEBD',
    }[props.status] || '#AFDBF1')};
    border-radius: 50%;
    border: ${props => ({
        error: '2px solid #D4231A',
        complete: '2px solid #178019',
    }[props.status] || '2px solid #10628A')};
    height: 50px;
    width: 50px;

    color: ${props => ({
        error: '#D4231A',
        complete: '#178019',
    }[props.status] || '#10628A')};
    font-family: 'equipextralight';
    font-size: 16px;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
`;
const FileUploadDefault = styled.div`
    font-family: 'equiplight';
    color: #424242;
    margin-left: 10px;
    word-wrap: break-word;
    width: fit-content;
    z-index: 5;
`;
const FileUploadName = styled.div`
    font-family: 'equiplight';
    color: #424242;
    margin-left: 10px;
    word-wrap: break-word;
    width: 80%;
    z-index: 5;

    @media (max-width: 500px) {
        width: 70%;
    }
`;
const FileUploadSize = styled.div`
    color: rgba(66, 66, 66, 0.35);
    font-family: 'equiplight';
    font-size: 12px;
    z-index: 5;
`;
const FileUploadDeleteButton = styled.a`
    z-index: 5;
    cursor: pointer;
`;

// File Upload Item Status Indicators
const ErrorMessage = styled.div`
    background-color: #D4231A;
    border-radius: 5px;
    color: #fff;
    font-family: 'equipextralight';
    font-size: 12px;
    margin: auto;
    padding: 3px 20px;
    text-align: center;
    width: fit-content;
`;

const SuccessMessage = styled.div`
    margin: auto;
    padding: 3px 20px;
    width: fit-content;
`;

// footer components
const FileUploadButton = styled.button<{
    disabled: boolean;
}>`
    background-color: ${props => props.disabled ? 'rgba(66, 66, 66, 0.35)' : '#1998d5'};
    border: ${props => props.disabled ? '1px solid rgba(66, 66, 66, 0.35)' : '1px solid #1998d5'};
    border-radius: 8px;
    height: 36px;
    color: #fff;
    font-size: 16px;
    font-family: 'equipextralight';
    margin: 5px;
    width: 125px;
    align-self: center;

    &:hover {
        background-color: ${props => props.disabled ? 'rgba(66, 66, 66, 0.75)' : '#1476ad'};
        border: ${props => props.disabled ? '1px solid rgba(66, 66, 66, 0.75)' : '1px solid #1476ad'};
        cursor: pointer;
    }
`;

type PresignedPosts = {
    [key: string]: {
        url: string;
        fields: {
            [key: string]: string;
        };
    };
};

type StartUploadResponse = {
    presignedPosts: PresignedPosts;
    presignedEncryptUrl: string;
};

type FileWrapper = {
    progress: number;
    file: File;
    errors?: FileError[];
};

type FileUploadAcceptedProps = {
    file: FileWrapper;
    onDelete: () => void;
    inProgress: boolean;
};

type FileUploadRejectedProps = {
    file: FileWrapper;
    onDelete: () => void;
};

type FileUploadDetailsProps = {
    file: File;
    onDelete: () => void;
    status: string;
    complete: boolean;
    inProgress: boolean;
};

const posixRegex = /^[\w\-.]+$/;
const stripInvalidCharacters = (name: string) => {
    return name.split('').filter(char => posixRegex.test(char)).join('');
}

const fileToBuffer = (file: File): Promise<Buffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result instanceof ArrayBuffer) {
                resolve(Buffer.from(reader.result));
            } else {
                reject(new Error('Failed to read file'));
            }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

const uint8ArrayToFile = (uint8Array: Uint8Array, fileName: string, fileType: string): File => {
    const blob = new Blob([uint8Array], { type: fileType });
    return new File([blob], fileName, { type: fileType });
};

const uploadFiles = async (
    apiPrefix: string,
    caseId: string,
    uploadId: string,
    files: File[],
    setUploadProgress: (fileIndex: number, progress: number) => void,
) => {
    const observable = of(files).pipe(
        // send start upload request
        map(files => ({
            caseId,
            uploadId,
            fileNames: files.map(file => file.name),
        })),
        mergeMap(startUploadRequest =>
            from(axios.post<StartUploadResponse>(`${apiPrefix}/upload-start`, startUploadRequest))
        ),
        catchError(err => {
            throw {
                error: err.response?.data?.error || 'An error occurred while starting upload',
            };
        }),
        // upload all files (2 max concurrent)
        mergeMap(startUploadResponse => from(Object.entries(startUploadResponse.data.presignedPosts)).pipe(
            mergeMap(async([fileName, uploadInfo], fileIndex) => {
                const { fields, url } = uploadInfo;
                const file = files[fileIndex];
                const data = await fileToBuffer(file);
                console.log(`Encrypting ${data.byteLength} bytes`);
                const {
                    encryptedData,
                    metadata,
                } = await encryptS3Data(startUploadResponse.data.presignedEncryptUrl, data);

                console.log(`Result: ${encryptedData.byteLength} bytes`);
                const encryptedFile = uint8ArrayToFile(encryptedData, fileName, file.type);
                const formData = new FormData();
                Object.entries(metadata)
                    .map(([key, value]) => ([`x-amz-meta-${key}`, value]))
                    .forEach(([key, value]) => formData.append(key, value));
                Object.entries(fields).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                formData.append('file', encryptedFile);
                return from(axios.post(url, formData, {
                    onUploadProgress: event => {
                        setUploadProgress(fileIndex, Math.max(0, Math.min(1, event.loaded / event.total)));
                    },
                })).pipe(
                    map(result => ({ result, fileIndex })),
                    tap(() => setUploadProgress(fileIndex, 1)),
                );
            }, 2),
            catchError(err => {
                throw {
                    error: err.response?.data?.error || 'An error occurred while uploading',
                };
            }),
            // convert to sorted array (results not used currently)
            toArray(),
            // map(results => results
            //     .sort((a, b) => a.fileIndex - b.fileIndex)
            //     .map(({ result }) => result)
            // ),
        )),
    );
    await lastValueFrom(observable);
};


// File Upload List Item (Accepted File)
function FileUploadAccepted(props: FileUploadAcceptedProps) {
    const {
        file: { progress, file },
        onDelete,
        inProgress
    } = props;

    return (
        <FileUploadItem>
            <FileProgressBar progress={progress} />
            <FileUploadDetails
                file={file}
                onDelete={onDelete}
                status={progress === 1 ? 'complete' : 'valid'}
                complete={progress === 1}
                inProgress={inProgress}
            />
        </FileUploadItem>
    );
}

// File Upload List Item (Rejected File)
function FileUploadRejected(props: FileUploadRejectedProps) {
    const {
        file: { file, errors },
        onDelete,
    } = props;

    return (
        <FileUploadItem>
            <FileUploadDetails file={file} onDelete={onDelete} status='error' complete={false} inProgress={false} />
            {errors?.map((error) => (
                <div key={error.code}>
                    <ErrorMessage>{error.message}</ErrorMessage>
                </div>
            ))}
        </FileUploadItem>
    );
}

// File Details
function FileUploadDetails({ file, onDelete, status, complete, inProgress }: FileUploadDetailsProps) {
    let fileName = file.name;
    const fileType = fileName.split('.').pop();
    if (fileName.length > 24) {
        fileName = `${fileName.substring(0, 18)}...${fileName.substring(fileName.length - 6)}` // show extension of the file
    }
    return (
        <FileUploadDetailsWrapper>
            <FileUploadIcon status={status}>{fileType}</FileUploadIcon>
            <FileUploadName>{fileName} <br /> <FileUploadSize>{beautifyBytes(file.size)}</FileUploadSize></FileUploadName>
            {complete ?
                <FileUploadDeleteButton>
                    <CompleteIcon />
                </FileUploadDeleteButton> :
                (!inProgress &&
                    <FileUploadDeleteButton onClick={onDelete}>
                        <DeleteIcon />
                    </FileUploadDeleteButton>)
            }

        </FileUploadDetailsWrapper>
    )
};

// Ensure height of page scales based on UI (mobile browser bar, etc)
function useViewportHeight() {
    const [vh, setVh] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setVh(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return vh;
}

/**
 * Class that holds the elements for the Ring User Lookup tool available to Ring Employees, has all the
 * elements of the page. Using Styled Components to style'
 */
function RingFileUploadNxUI() {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();

    const [expired, setExpired] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState('');
    const [expiringSoon, setExpiringSoon] = useState('');
    const [inProgress, setInProgress] = useState(false);

    const [files, setFiles] = useState<FileWrapper[]>([]);

    const [caseId, setCaseId] = useState('');
    const [uploadId, setUploadId] = useState('');
    const [expiresAt, setExpiresAt] = useState(0);

    const vh = useViewportHeight();

    // read url parameters and clear them
    useEffect(() => {
        const searchParamsMap: { [key: string]: string } = {};
        searchParams.forEach((value, key) => searchParamsMap[key] = value);

        const {
            caseId,
            uploadId,
            expiresAt,
        } = searchParamsMap;

        if (caseId && uploadId && expiresAt) {
            setCaseId(caseId);
            setUploadId(uploadId);
            setExpiresAt(+expiresAt);
        }

        delete searchParamsMap.caseId;
        delete searchParamsMap.uploadId;
        delete searchParamsMap.expiresAt;

        setSearchParams(searchParamsMap);
    }, [searchParams]);

    // check and set expires
    useEffect(() => {
        const expiryCheck = () => {
            const now = Date.now() + (2 * 60 * 1000); // 2 minutes padding

            const millisLeft = expiresAt - now;
            setExpired(millisLeft <= 0);

            let expiringSoon = '';
            if (millisLeft > 0) {
                const minutesLeft = Math.floor(millisLeft / (60 * 1000));

                if (2 <= minutesLeft && minutesLeft <= 5) {
                    expiringSoon = `${minutesLeft} ${t('minutes')}`;
                } else if (minutesLeft === 1) {
                    expiringSoon = t('1 minute');
                } else if (minutesLeft === 0) {
                    expiringSoon = t('less than a minute');
                }
            }
            setExpiringSoon(expiringSoon);
        };

        expiryCheck();
        const intervalId = setInterval(expiryCheck, 1000);
        return () => clearInterval(intervalId);
    }, [expiresAt]);

    // Disable Upload Button
    const {
        uploadDisabled,
        uploadError,
    } = useMemo(() => {
        let uploadDisabled = false;
        let uploadError = '';
        // max & min file count
        if (files.length <= 0 || files.find(({ errors }) => errors)) {
            uploadDisabled = true;
        } else if (files.length > 5) {
            uploadDisabled = true;
            uploadError = t('Maximum of 5 files');
        } else if (inProgress) {
            uploadDisabled = true;
        }

        return {
            uploadDisabled,
            uploadError,
        };
    }, [files, inProgress]);

    // duplicate file name & file size check
    const fileValidation = (file: File) => {
        // if (fileNames.has(file.name)) {
        //     return {
        //         code: "duplicate-file",
        //         message: `Duplicate file exists`
        //     };
        // }
        const fileName = stripInvalidCharacters(file.name);
        if(fileName.startsWith(".") || fileName.startsWith("..")) {
            return {
                code: "invalid-start",
                message: t("File name cannot start with . or ..")
            };
        }
        if(fileName.includes(".")) {
            const ext = fileName.split('.');
            if (ext.length > 2) {
                return {
                    code: "multiple-extensions",
                    message: t("File name has multiple file extensions")
                };
            }
        }
        if (file.size >= MAX_FILE_SIZE) {
            return {
                code: "file-too-large",
                message: t("File is larger than 20 MB")
            };
        }
        // If there are no validation errors, return null.
        return null;
    };

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {

        const renameDuplicateFile = (file: File) => {
            let suffix = 1;
            const fileName = stripInvalidCharacters(file.name);
            const fileParts = fileName.split('.');
            const fileExtension = fileParts.pop(); // Get the file extension
            let baseName = fileParts.join('.'); // Rejoin the remaining parts

            let newName = `${baseName}-${suffix}.${fileExtension}`;
            while (new Set(files.map(f => f.file.name)).has(newName)) {
                suffix++;
                newName = `${baseName}-${suffix}.${fileExtension}`;
            }
            return new File([file], newName, { type: file.type });
        };

        // Rename any files that have duplicate names
        acceptedFiles = acceptedFiles.map(file => {
            const fileName = stripInvalidCharacters(file.name);
            if (new Set(files.map(f => f.file.name)).has(fileName)) {
                return renameDuplicateFile(file);
            }
            return new File([file], fileName, { type: file.type });
        });

        // update File error message for invalid file type
        rejectedFiles.forEach(rejection => {
            rejection.errors.forEach(error => {
                if (error.code === 'file-invalid-type') {
                    error.message = t('File Type is not Supported')
                }
            })
        });

        const acceptedFilesMap: FileWrapper[] =
            acceptedFiles.map(file => ({
                file,
                progress: 0,
            }));
        const rejectedFilesMap: FileWrapper[] =
            rejectedFiles.map(rejection => ({
                ...rejection,
                progress: 0,
            }));

        setFiles((selectedFiles) => [...selectedFiles, ...acceptedFilesMap, ...rejectedFilesMap]);
    }, [apiPrefix, caseId, uploadId, expiresAt, files]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ALLOWED_FILE_TYPES,
        disabled: completed,
        validator: fileValidation
    });

    const uploadFileCallback = useCallback(() => {
        setInProgress(true);
        const filesArray = files.map(({ file }) => file);
        const setUploadProgress = (fileIndex: number, progress: number) => {
            setFiles(oldFiles => {
                const files = [...oldFiles];
                files[fileIndex] = { ...files[fileIndex], progress };
                return files;
            });
        }
        uploadFiles(apiPrefix, caseId, uploadId, filesArray, setUploadProgress)
            .then(
                () => setCompleted(true),
                ({ error }) => {
                    console.log(error);
                    if (error === 'GoneError: Upload request has expired') {
                        setError(t('This link has expired') as string);
                    } else if(error === 'GoneError: Upload request has already been used') {
                        setError(t('Upload request has already been used') as string);
                    } else {
                        setError(t('An error occurred while uploading') as string);
                    }
                },
            ).finally(() => setInProgress(false));

    }, [apiPrefix, caseId, uploadId, files]);

    const deleteFile = useCallback((fileIndex: number) => {
        setFiles(files => files.filter((_, i) => i !== fileIndex));
    }, [files]);

    const direction = getI18n().dir()
    return (
        <Page vh={vh} dir={direction}>
            <Content>
                <FileUploadDiv>
                    <FileUploadHeader>
                        {completed ? (<>
                            <FileUploadSuccess>{t('Upload Successful')}</FileUploadSuccess>
                            <SuccessMessage>
                                <Branding
                                    header={t('Neighbor Tools')}
                                    subheader={t('File Upload')}
                                />
                            </SuccessMessage>
                        </>
                        ) : error ? (
                            <FileUploadError>{error}</FileUploadError>
                        ) : expired ? (
                            <FileUploadError>{t("This link has expired")}</FileUploadError>
                        ) : uploadError ? (
                            <FileUploadError>{uploadError}</FileUploadError>
                        ) : inProgress ? (
                            <FileUploadProgress>{t('Upload in progress') + '...'}</FileUploadProgress>
                        ) : (<>
                            {expiringSoon ?
                                <FileUploadError> {t('This link is expiring soon:')} {expiringSoon} </FileUploadError> :
                                <FileUploadStatus> {t('Secure Connection Established')} </FileUploadStatus>
                            }
                        </>
                        )}
                    </FileUploadHeader>
                    {/* Hide Upload UI on Expired Link */}
                    {!expired && <>
                        <FileUploadRow>
                            {/* Hide Dropzone on Successful Upload */}
                            {!completed &&
                                <FileUploadDropzoneColumn>
                                    <FileDropzone {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <IconWrapper>
                                            <DownloadIcon />
                                        </IconWrapper>
                                        {isDragActive ?
                                            <p className='lg'>{t('Drop the file here')}</p>
                                            : files.length > 0 ?
                                                <p className='lg'>{files.length} {t('file(s) selected for upload')}</p>
                                                : <>
                                                    <p className='lg'>
                                                        <BrowseIndicator>
                                                        {t('Drag and Drop file or Browse')}
                                                        </BrowseIndicator>
                                                    </p>
                                                </>
                                        }
                                        <DisabledText>
                                            {uploadError}
                                        </DisabledText>
                                    </FileDropzone>
                                </FileUploadDropzoneColumn>
                            }
                            <FileUploadListColumn>
                                <FileUploadList>
                                    {files.length > 0 ? files.map((file, index) => (
                                        file.errors ? (
                                            <FileUploadRejected
                                                file={file}
                                                onDelete={() => deleteFile(index)}
                                            />
                                        ) : (
                                            <FileUploadAccepted
                                                file={file}
                                                onDelete={() => deleteFile(index)}
                                                inProgress={inProgress}
                                            />
                                        )
                                    )) : <>
                                        <FileUploadItem>
                                            <FileUploadDefault>{t('Select a File to begin (5 files max)')} <br />
                                                <FileUploadSize>{t('Maximum File Size: 20 MB')}</FileUploadSize>
                                            </FileUploadDefault>
                                        </FileUploadItem>
                                    </>}
                                </FileUploadList>
                            </FileUploadListColumn>
                        </FileUploadRow>
                        {/* Hide Footer on Successful Upload */}
                        {!completed &&
                            <FileUploadFooter>
                                <Branding
                                    header={t('Neighbor Tools')}
                                    subheader={t('File Upload')}
                                />
                                <FileUploadButton
                                    onClick={uploadFileCallback}
                                    disabled={uploadDisabled}
                                >
                                    {t('Upload')}
                                </FileUploadButton>
                            </FileUploadFooter>
                        }
                    </>}
                </FileUploadDiv>
            </Content>
        </Page>
    )
}

export default RingFileUploadNxUI;
