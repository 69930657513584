{
	"This link has expired": "このリンクは有効期限が切れています",
	"Secure Connection Established": "安全な接続が確立されました",
	"Select a File to begin (5 files max)": "アップロードするファイルを選択 (最大5ファイル)",
	"Maximum File Size: 20 MB": "最大ファイルサイズ: 20MB",
	"Drag and Drop file or Browse": "ファイルをドラッグ＆ドロップまたはファイルを選択",
	"Upload": "アップロード",
	"Neighbor Tools": "Ringの便利ツール",
	"File Upload": "ファイルのアップロード",
	"file(s) selected for upload": "個のファイルが選択されました",
	"Maximum of 5 files": "最大5ファイル",
	"Upload in progress": "アップロード中です",
	"Upload Successful": "アップロードに成功しました",
	"File is larger than 20 MB": "ファイルのサイズが20MBを超えています",
	"File name has multiple file extensions": "ファイル名に複数の異なる形式の拡張子が含まれています",
	"File name cannot start with . or ..": "ファイル名の先頭にドット「.」または二重ドット「..」を使用することはできません",
	"Maximum of 5 files": "最大5ファイル",
	"This link is expiring soon:": "このリンクはまもなく有効期限が切れます:",
	"minutes": "分",
	"less than a minute": "1分未満",
	"1 minute": "1分",
	"Secure Connection Established": "安全な接続が確立されました",
	"Drop the file here": "ここにファイルをドロップ",
	"An error occurred while uploading": "アップロード中にエラーが発生しました",
	"File Type is not Supported": "対応していないファイル形式です",
	"BadRequestError: File name contains invalid characters": "リクエストが無効です: ファイル名に無効な文字が含まれています",
	"An error has occurred": "エラーが発生しました",
	"Upload request has already been used": "アップグレードリクエストはすでに使用されています"
}