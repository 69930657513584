import React from 'react';
import styled from 'styled-components';

const StyledCircle = styled.circle`
    fill: #1c9ad6;
`;
const StyledPath = styled.path`
    fill: #535556;
`;

type Props = {
}

function Logo(props: Props) {
    return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.85 122.05">
                <g>
                    <g>
                        <g>
                            <StyledCircle cx="56.14" cy="6.71" r="6.71" />
                            <g>
                                <StyledPath
                                    d="M40.44,32.1a3.76,3.76,0,0,1-1.18,2.75A3.92,3.92,0,0,1,36.38,36a4.08,4.08,0,0,1-2.75-1.18l-.13-.13a1.21,1.21,0,0,0-.26-.2,1,1,0,0,1-.27-.2A15.43,15.43,0,0,0,22.9,30.79q-7.07,0-11.06,4.84t-4,13.74V91.94a3.53,3.53,0,0,1-1.24,2.95,4.26,4.26,0,0,1-2.68,1,4.26,4.26,0,0,1-2.68-1A3.52,3.52,0,0,1,0,91.94V49.37A35.42,35.42,0,0,1,1.44,39.1a24.63,24.63,0,0,1,4.32-8.37A20.67,20.67,0,0,1,12.89,25a22.43,22.43,0,0,1,10-2.09,25,25,0,0,1,8.64,1.7,20.89,20.89,0,0,1,7.59,4.71A3.89,3.89,0,0,1,40.44,32.1Z" />
                                <StyledPath
                                    d="M60.06,91.94a3.53,3.53,0,0,1-1.24,2.95,4.15,4.15,0,0,1-5.37,0,3.52,3.52,0,0,1-1.24-2.95V28.17a3.53,3.53,0,0,1,1.24-2.95,4.16,4.16,0,0,1,5.37,0,3.54,3.54,0,0,1,1.24,2.95Z" />
                                <StyledPath
                                    d="M128.12,91.94a3.53,3.53,0,0,1-1.25,2.95,4.15,4.15,0,0,1-5.36,0,3.52,3.52,0,0,1-1.24-2.95V48.46a17.2,17.2,0,0,0-1.38-6.87,17.77,17.77,0,0,0-9.42-9.42,17.87,17.87,0,0,0-13.74,0,17.78,17.78,0,0,0-9.42,9.42,17.27,17.27,0,0,0-1.37,6.87V91.94a3.52,3.52,0,0,1-1.24,2.95,4.15,4.15,0,0,1-5.37,0,3.52,3.52,0,0,1-1.24-2.95V48.46a24.83,24.83,0,0,1,2-9.95,25.68,25.68,0,0,1,5.43-8.12,26,26,0,0,1,8-5.43,25.57,25.57,0,0,1,20,0,25.1,25.1,0,0,1,13.54,13.54,24.89,24.89,0,0,1,2,9.95Z" />
                                <StyledPath
                                    d="M194.85,96.52a24.76,24.76,0,0,1-2,10A25.28,25.28,0,0,1,179.28,120a24.91,24.91,0,0,1-9.95,2,30,30,0,0,1-5.76-.59,32.88,32.88,0,0,1-6-1.77,25.85,25.85,0,0,1-5.49-3,16.4,16.4,0,0,1-4.12-4.19,3.74,3.74,0,0,1-.65-2.23,3.59,3.59,0,0,1,1.24-2.81,4.09,4.09,0,0,1,2.81-1.12,4.49,4.49,0,0,1,1.63.33,3.18,3.18,0,0,1,1.51,1.37.37.37,0,0,0,.13.26l.52.53a14.66,14.66,0,0,0,6.48,4.12,24.78,24.78,0,0,0,7.65,1.25,17.22,17.22,0,0,0,6.87-1.38,17.77,17.77,0,0,0,9.42-9.42A17.17,17.17,0,0,0,187,96.52v-9a25.43,25.43,0,0,1-8,5.17,25.08,25.08,0,0,1-9.68,1.9,24.73,24.73,0,0,1-10-2,25.47,25.47,0,0,1-13.48-13.48,24.69,24.69,0,0,1-2-10V48.46a24.83,24.83,0,0,1,2-9.95,25.61,25.61,0,0,1,5.43-8.12,26,26,0,0,1,8-5.43,25.57,25.57,0,0,1,20,0,25.09,25.09,0,0,1,13.54,13.54,24.89,24.89,0,0,1,2,9.95V96.52ZM187,48.46a17.2,17.2,0,0,0-1.38-6.87,17.77,17.77,0,0,0-9.42-9.42,17.87,17.87,0,0,0-13.74,0A17.79,17.79,0,0,0,153,41.58a17.33,17.33,0,0,0-1.37,6.87V69A17.32,17.32,0,0,0,153,75.92a17.81,17.81,0,0,0,3.8,5.63,17.63,17.63,0,0,0,5.63,3.79,17.87,17.87,0,0,0,13.74,0,17.77,17.77,0,0,0,9.42-9.42A17.19,17.19,0,0,0,187,69Z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
    );
}

export default Logo;