{
    "default": {
        "api_prefix": "https://fileupload.cs-tools.dev.ring.com/api"
    },
    "fileupload.cs-tools.dev.ring.com": {
        "api_prefix": "https://fileupload.cs-tools.dev.ring.com/api"
    },
    "fileupload.cs-tools.qa.ring.com": {
        "api_prefix": "https://fileupload.cs-tools.qa.ring.com/api"
    },
    "fileupload.cs-tools.ring.com": {
        "api_prefix": "https://fileupload.cs-tools.ring.com/api"
    }
}
