{
	"This link has expired": "Dieser Link ist abgelaufen",
	"Secure Connection Established": "Sichere Verbindung hergestellt",
	"Select a File to begin (5 files max)": "Wähle eine Datei aus, um zu beginnen (max. 5 Dateien)",
	"Maximum File Size: 20 MB": "Maximale Dateigröße: 20 MB",
	"Drag and Drop file or Browse": "Datei per Drag-and-Drop ablegen oder Ordner durchsuchen",
	"Upload": "Hochladen",
	"Neighbor Tools": "Tools für Nutzer",
	"File Upload": "Datei-Upload",
	"file(s) selected for upload": "zum Hochladen ausgewählte Datei(en)",
	"Maximum of 5 files": "Maximal 5 Dateien",
	"Upload in progress": "Upload läuft",
	"Upload Successful": "Upload erfolgreich",
	"File is larger than 20 MB": "Datei ist größer als 20 MB",
	"File name has multiple file extensions": "Dateiname hat mehrere Dateierweiterungen",
	"File name cannot start with . or ..": "Dateiname darf nicht mit . oder .. beginnen",
	"Maximum of 5 files": "Maximal 5 Dateien",
	"This link is expiring soon:": "Dieser Link läuft demnächst ab:",
	"minutes": "Minuten",
	"less than a minute": "weniger als eine Minute",
	"1 minute": "1 Minute",
	"Secure Connection Established": "Sichere Verbindung hergestellt",
	"Drop the file here": "Datei hier ablegen",
	"An error occurred while uploading": "Beim Hochladen ist ein Fehler aufgetreten",
	"File Type is not Supported": "Dateityp wird nicht unterstützt",
	"BadRequestError: File name contains invalid characters": "BadRequestError: Dateiname enthält ungültige Zeichen",
	"An error has occurred": "Ein Fehler ist aufgetreten",
	"Upload request has already been used": "Upload-Anforderung wurde bereits verwendet"
}